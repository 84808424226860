import { Confirmation } from '@/enums/common.enum';
import type { DashboardWidget } from '@/types/group-home/group-home-model.type';
import type {
  DashboardComponentRequest,
  DashboardComponentsRequest
} from '@/types/group-home/group-home-request.type';

export const updateDashboardComponentsMapper = (
  models: DashboardWidget[]
): DashboardComponentsRequest => {
  const data: DashboardComponentRequest[] = models.map((model: DashboardWidget, index: number) => {
    return {
      groupDashboardComponentNo: model.groupDashboardComponentNo,
      dashboardComponentNo: model.dashboardComponentNo,
      componentType: model.id,
      activeYn: model.isActive ? Confirmation.YES : Confirmation.NO,
      sortNo: index + 1
    };
  });
  return {
    components: data
  };
};
