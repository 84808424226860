import { useRequest } from '@/composables/useRequest';
import { ApiMethod, HeaderTypes } from '@/enums/api.enum';
import { updateDashboardComponentsMapper } from '@/mappers/group-home.mapper';
import type { Pagination } from '@/types/common/pagination.type';
import type { DashboardWidget } from '@/types/group-home/group-home-model.type';
import type {
  DashboardSettingProductRequest,
  HashtagCountByProductRequest,
  IndieWishlistCountByProductsRequest,
  PartnerBannersRequest,
  PostsAndCommentsCountRequest
} from '@/types/group-home/group-home-request.type';
import type {
  DashboardComponentResponse,
  DashboardComponentsResponse,
  DashboardSettingProductResponse,
  DashboardSettingProductsResponse,
  HashtagCountByProductResponse,
  PartnerBannerResponse,
  PostsAndCommentsCountResponse,
  ProjectsProductsCountResponse,
  WishlistCountByProductResponse,
  WishlistCountByProductsResponse
} from '@/types/group-home/group-home-response.type';
import { camelToSnake, generateHeader } from '@/utils/api.util';

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+group+home+%7C+get+group+dashboard+products
export const fetchGroupDashboardProductsApi = async (
  groupId: string
): Promise<DashboardSettingProductResponse[]> => {
  const { data } = await useRequest<DashboardSettingProductsResponse>(
    `studio/v1/group-dashboard/${groupId}/products`,
    {
      headers: {
        ...generateHeader(HeaderTypes.XLang, HeaderTypes.XNation)
      },
      method: ApiMethod.Get
    }
  );

  return data?.products ?? [];
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+group+home+%7C+update+group+dashboard+products
export const updateGroupDashboardProductsApi = async (
  groupId: string,
  sortedProducts: DashboardSettingProductRequest[]
): Promise<DashboardSettingProductResponse[]> => {
  const { data } = await useRequest<DashboardSettingProductsResponse>(
    `studio/v1/group-dashboard/${groupId}/products`,
    {
      headers: {
        ...generateHeader(HeaderTypes.XLang, HeaderTypes.XNation)
      },
      method: ApiMethod.Put,
      data: camelToSnake({
        sortedProductNos: sortedProducts
      })
    }
  );

  return data?.products ?? [];
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+group+home+%7C+get+group+dashboard+components
export const fetchGroupDashboardComponentsApi = async (
  groupId: string,
  activeYOnly: boolean
): Promise<DashboardComponentResponse[]> => {
  const { data } = await useRequest<DashboardComponentsResponse>(
    `studio/v1/group-dashboard/${groupId}`,
    {
      method: ApiMethod.Get,
      params: camelToSnake({
        activeYOnly: activeYOnly ? 'Y' : 'N'
      })
    }
  );

  return data?.components ?? [];
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+group+home+%7C+update+group+dashboard+component
export const updateGroupDashboardComponentApi = async (
  groupId: string,
  components: DashboardWidget[]
): Promise<boolean> => {
  const requestData = updateDashboardComponentsMapper(components);
  const { data } = await useRequest<boolean>(`studio/v1/group-dashboard/${groupId}`, {
    method: ApiMethod.Put,
    data: camelToSnake(requestData)
  });

  return data ?? false;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=449974402
export const fetchIndieWishlistCountByProductsApi = async (
  requestData: IndieWishlistCountByProductsRequest,
  showLoading: boolean = true
): Promise<WishlistCountByProductResponse[]> => {
  const { data } = await useRequest<WishlistCountByProductsResponse>(
    'shop/v2.0/indie/studio/item/wishlist/count',
    {
      method: ApiMethod.Post,
      data: camelToSnake(requestData),
      showLoading
    }
  );

  return data?.list ?? [];
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.+studio+%7C+group+home+%7C+get+group+projects%2C+products+count
export const fetchGroupProjectsProductsCountApi = async (
  groupId: string
): Promise<ProjectsProductsCountResponse | undefined> => {
  const { data } = await useRequest<ProjectsProductsCountResponse>(
    `studio/v1/group/${groupId}/projects/products/count`,
    {
      method: ApiMethod.Get
    }
  );

  return data;
};

// https://wiki.smilegate.net/display/SDKAPIDOCU/WDB03.studio+%7C+Banner+-+Get+Banners
export const fetchPartnerBannerApi = async (
  params: PartnerBannersRequest,
  getDefault: boolean = true
): Promise<Pagination<PartnerBannerResponse[]> | undefined> => {
  let headers: { [key: string]: string | undefined } = {
    [HeaderTypes.XLang]: undefined
  };
  if (!getDefault) {
    headers = {
      ...generateHeader(HeaderTypes.XLang, HeaderTypes.XNation)
    };
  }
  const { data } = await useRequest<Pagination<PartnerBannerResponse[]>>(
    'studio/v1/studio-banners',
    {
      headers,
      method: ApiMethod.Get,
      params: camelToSnake(params)
    }
  );

  return data;
};
// https://wiki.smilegate.net/pages/viewpage.action?pageId=452414334
export const fetchPostsAndCommentsCountApi = async (
  boardSeq: number,
  params: PostsAndCommentsCountRequest,
  showLoading: boolean = true
): Promise<PostsAndCommentsCountResponse | undefined> => {
  const { data } = await useRequest<PostsAndCommentsCountResponse>(
    `cwms/v1.0/board/${boardSeq}/content/count`,
    {
      headers: {
        ...generateHeader(
          HeaderTypes.XLang,
          HeaderTypes.XNation,
          HeaderTypes.CallerId,
          HeaderTypes.CallerDetail
        )
      },
      method: ApiMethod.Get,
      params: camelToSnake(params),
      showLoading
    }
  );

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=452414506
export const fetchHashTagCountApi = async (
  params: HashtagCountByProductRequest
): Promise<HashtagCountByProductResponse | undefined> => {
  const { data } = await useRequest<HashtagCountByProductResponse>(
    'postie/v1.0/article/tagged/count',
    {
      headers: {
        ...generateHeader(
          HeaderTypes.XLang,
          HeaderTypes.XNation,
          HeaderTypes.CallerId,
          HeaderTypes.CallerDetail
        )
      },
      method: ApiMethod.Get,
      params: camelToSnake(params),
      showCommonError: false
    }
  );

  return data;
};
