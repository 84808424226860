import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import {
  fetchGroupDashboardComponentsApi,
  fetchGroupDashboardProductsApi,
  fetchGroupProjectsProductsCountApi
} from '@/apis/group-home.api';
import type {
  DashboardComponentResponse,
  DashboardSettingProductResponse,
  ProjectsProductsCountResponse
} from '@/types/group-home/group-home-response.type';

export const useGroupHomeStore = defineStore('groupHomeStoreTemp', () => {
  const selectedProducts = ref<DashboardSettingProductResponse[]>([]);
  const dashboardComponents = ref<DashboardComponentResponse[]>([]);

  const projectsProductsCounts = ref<ProjectsProductsCountResponse>();

  const isSelectedProducts = computed<boolean>(() => selectedProducts.value.length > 0);

  const getGroupDashboardComponents = async (groupId: string, activeOnly: boolean = false) => {
    const res = await fetchGroupDashboardComponentsApi(groupId, activeOnly);
    if (res) {
      dashboardComponents.value = res;
    }
  };

  const getGroupDashboardSelectedProducts = async (groupId: string) => {
    const res = await fetchGroupDashboardProductsApi(groupId);
    if (res) {
      selectedProducts.value = res;
    }
  };

  const getGroupProjectsProductsCounts = async (groupId: string) => {
    const res = await fetchGroupProjectsProductsCountApi(groupId);
    if (res) {
      projectsProductsCounts.value = res;
    }
  };

  // Todo: check product release status => components will show empty state for none release product.

  return {
    isSelectedProducts,
    selectedProducts,
    dashboardComponents,
    projectsProductsCounts,
    getGroupDashboardSelectedProducts,
    getGroupDashboardComponents,
    getGroupProjectsProductsCounts
  };
});
